@media (prefers-reduced-motion: no-preference) {
  .invalidEntry {
    animation-duration: 500ms;
    animation-name: shake;
    animation-iteration-count: 1;
  }

  /* .invalidEntry .MuiTextField-root {
    animation-duration: 50ms;
    animation-name: redBlink;
    animation-iteration-count: 8;
    animation-direction: alternate;
  } */

  .charChanged {
    animation-duration: 50ms;
    animation-name: dip;
    animation-timing-function: ease-out;
    animation-iteration-count: 2;
    animation-direction: alternate;
  }
}

@keyframes dip {
  from {
  }
  to {
    transform: translateY(8px);
  }
}

@keyframes redBlink {
  from {
  }
  to {
    background-color: hotpink;
  }
}

@keyframes shake {
  0% {
    transform: translate(5px, 0px);
  }
  10% {
    transform: translate(-5px, 0px);
  }
  20% {
    transform: translate(4px, 0px);
  }
  30% {
    transform: translate(-4px, 0px);
  }
  40% {
    transform: translate(3px, 0px);
  }
  50% {
    transform: translate(-3px, 0px);
  }
  60% {
    transform: translate(2px, 0px);
  }
  70% {
    transform: translate(-2px, 0px);
  }
  80% {
    transform: translate(1px, 0px);
  }
  90% {
    transform: translate(-1px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
